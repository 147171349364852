import modalContent from "../../../../tenant-app/src/components/modal-content"
import companyDetails from "../../components/companyDetails"
import { getCompanyDetails, getDomainDetails, getOwnershiDetails, getjurisdictionList, } from "../search-records/services"
import selectEntities from "../select-entities"
import Button from "../../../components/button"

export default {
    name: "searched-records",
    props: {
        showEntitiesSelection: Boolean,
    },
    data: function () {
        return {
            columns: [{
                label: "Selection",
                field: "selection",
                sortable: false,
                width: '100px'
            },
            {
                label: "Company Name",
                field: "name",
                width: "300px",
                sortable: false
            }, {
                label: "Jurisdiction",
                field: "jurisdiction",
                sortable: false
            }, {
                label: "Company Reg. No.",
                field: "company_number",
                sortable: false
            }, {
                label: "Address",
                field: "registered_address_in_full",
                sortable: false
            }, {
                label: "Company Type",
                field: "company_type",
                sortable: false
            }, {
                label: "Incorporation Date",
                field: "incorporation_date",
                sortable: false
            }, {
                label: "Previous/ Trading Names",
                field: "previous_names",
                sortable: false
            }, {
                label: "Status",
                field: "inactive",
                sortable: false
            }],
            domainColumns: [{
                label: "Selection",
                field: "selection",
                sortable: false,
                width: '100px'
            },
            {
                label: "Domain",
                field: "domain",
                sortable: false
            },
            {
                label: "Company Name",
                field: "name",
                width: "300px",
                sortable: false
            },
            {
                label: "Description",
                field: "description",
                sortable: false
            }],
            skipCheck: false,
            skipCorporate: false,
            companyDetails: null,
            companyDetailsData: null,
            officersData: null,
            ownershipData: null,
            previousNamesData: null,
            showEntitiesSelection: false,
            loadingData: true,
            jurisdictionData: null,
            companyData: null,
            rowsData: null,
            rowsDetailData: null,
            selectedRow: null,
            selectedCompanyData: null,
            selectSection: "false",
        }
    },
    components: {
        "modal-content": modalContent,
        "company-details": companyDetails,
        "select-entities": selectEntities,
        Button
    },
    async mounted() {
        await this.jurisdictionList()
        await this.getCompanyData()
        this.loadingData = false
        this.selectSection = this.$route.query.selectEntity.toString()
    },
    computed: {

        curentroutes() {
            return this.$route.query.selectEntity
        },
        searchedQuery() {
            return this.$route.query
        },
        currentPage() {

            return this.$route.params.type
        },
        companyAllData() {
            this.$store.state.getCompanyAllData
        }

    },
    methods: {
        currentSection() {
            if (this.$route.params.type == 'searched-records' && (this.$route.query.selectEntity == 'false')) {
                return true
            }
            else {
                return false
            }
        },
        async getCompanyData() {
            let companySearchPayload = {
                name: this.searchedQuery.name,
                request_id: this.searchedQuery.queryId,
                "page": 1,
                "per_page": 10,
            }
            let domainPayload = { query: this.searchedQuery.name }
            if (this.searchedQuery.jurisdiction) {
                const code = this.jurisdictionData.filter(res => res.label == this.searchedQuery.jurisdiction)
                companySearchPayload["code"] = code[0].value
            }
            let companyAllData = this.$store.getters.getCompanyAllData
            if (!companyAllData.length) {
                await this.$store.dispatch('setSearchData', { companySearchPayload, domainPayload });
            }
            companyAllData = this.$store.getters.getCompanyAllData;
            const { companySearchData, companyDomainData } = companyAllData
            let companyData = companySearchData.map(res => res.company)
            companyData.forEach((res) => {
                const data = this.jurisdictionData.filter(r => r.value == res.jurisdiction_code);
                res.jurisdiction = data[0].label;
                res.selected = false;
            })
            // let DomainData = companyDomainData.map((item) => {
            //     let domaindata = item.domain
            //     let payload = { domain: domaindata }
            //     let result = this.getapidata(payload)
            //     console.log(result)
            //     return { ...item, description: result.data.data.description }
            // })
            this.companyData = companySearchData;
            this.rowsData = companyData;
            this.rowsDetailData = companyDomainData;
            let promises = this.rowsDetailData.map(item => {
                return getDomainDetails({ domain: item.domain })
            })
            const descriptionData = await Promise.allSettled(promises)
            descriptionData.map((item, i) => {
                if (item.status === "fulfilled") {
                    const description = item.value.data.data.description
                    this.rowsDetailData[i].description = description
                }
            })
            this.rowsDetailData = JSON.parse(JSON.stringify(this.rowsDetailData))
            // const casePayload={case_name:this.searchedQuery.name,neo_case_id:this.searchedQuery.neo_case_id}
            // await updateCaseName(casePayload)
        },
        getPreviousNames(names) {
            let previousNames = names.map(res => res.company_name)
            return previousNames.join()
        },
        setSelectedRow(data) {
            this.selectedRow = data.row
            this.rowsData.forEach((res) => {
                if (res.company_number == this.selectedRow.company_number) {
                    res.selected = true;
                }
                else {
                    res.selected = false;
                }
            })
        },
        handleSkip(e) {
            if (e.target.name == "skipCheck") {
                this.skipCheck = !this.skipCheck
            }
            else {
                this.skipCorporate = !this.skipCorporate
            }
        },
        async handleCompanyDetails(data, selected) {
            const selectedCompanyData = this.companyData.filter(res => res.company.company_number == data.row.company_number);
            const payload = {
                card_type: "company",
                code: data.row.jurisdiction_code,
                doc_id: selectedCompanyData[0].doc_id,
                number: selectedCompanyData[0].company.company_number,
                query_id: this.searchedQuery.queryId,
            }
            const companyDetails = await getCompanyDetails(payload)
            this.companyDetails = companyDetails.data.data;
            this.companyDetailsData = selectedCompanyData[0].company;
            if (selected) {
                this.officersData = this.companyDetails.company?.officers.map(res => res.officer)
                this.previousNamesData = this.companyDetails.company?.previous_names
            }
            else {
                this.$refs["company-records"].showModal();
            }
        },
        goBack() {
            if (this.currentPage == 'searched-records' && !this.showEntitiesSelection) {
                this.$router.push({
                    name: "search-company-records", params: { type: "company-search" }, query: {
                        package_id: this.$route.query.package_id,
                        client_id: this.$route.query.client_id
                    }
                })
            }
            else {
                this.check = true
                this.showEntitiesSelection = false
                this.officersData = null
                this.ownershipData = null
                this.previousNamesData = null
            }
        },
        async handleRecordsSelection() {
            if (this.skipCheck || this.skipCorporate) {
                // this.$toast.error("Please select company")
                // this.showEntitiesSelection = true;
                this.selectSection = 'true';
                this.$router.push({
                    name: "search-company-records",
                    params: { type: "searched-records" },
                    query: {
                        queryId: this.searchedQuery.queryId,
                        name: this.searchedQuery.name,
                        jurisdiction: this.searchedQuery.jurisdiction,
                        case_id: this.searchedQuery.case_id,
                        package_id: this.searchedQuery.package_id,
                        client_id: this.searchedQuery.client_id,
                        neo_uuid: this.searchedQuery.neo_uuid,
                        selectEntity: true,
                        skip: true
                    }
                })

                return;
            }
            else {
                const selectedCompanyData = this.companyData.filter(res => res.company.company_number == this.selectedRow.company_number);
                const payload = {
                    card_type: "company",
                    code: this.selectedRow.jurisdiction_code,
                    doc_id: this.selectedRow.doc_id,
                    number: this.selectedRow.company_number,
                    query_id: this.searchedQuery.queryId,
                }
                this.selectedCompanyData = selectedCompanyData[0];
                const companyDetails = await getCompanyDetails(payload);
                const newPayload = { comp_id: this.selectedRow.company_number };
                let owner = await getOwnershiDetails(newPayload);
                this.ownershipData = owner.data.entity;
                this.companyDetails = companyDetails.data.data;
                this.companyDetailsData = this.selectedRow.company;
                this.officersData = this.companyDetails.company?.officers.map(res => res.officer);
                this.previousNamesData = this.companyDetails.company?.previous_names;
                // this.showEntitiesSelection = true;
                this.selectSection = 'true'
                this.$router.push({
                    name: "search-company-records",
                    params: { type: "searched-records" },
                    query: {
                        queryId: this.searchedQuery.queryId,
                        name: this.searchedQuery.name,
                        jurisdiction: this.searchedQuery.jurisdiction,
                        case_id: this.searchedQuery.case_id,
                        package_id: this.searchedQuery.package_id,
                        client_id: this.searchedQuery.client_id,
                        neo_uuid: this.searchedQuery.neo_uuid,
                        selectEntity: true
                    }
                })
            }
        },
        async jurisdictionList() {
            const result = await getjurisdictionList()
            this.jurisdictionData = result.data.data
        },

    }

}