import store from "../../../../tenant-app/src/store"
import Button from "../../../components/button"
import { updateEntity, } from "../search-records/services"

export default {
    name: "select-entities",
    data: function () {
        return {
            officerColumns: [
                {
                    label: "Name",
                    field: "name",
                    sortable: false
                }, {
                    label: "Position",
                    field: "position",
                    sortable: false
                }, {
                    label: "Status",
                    field: "inactive",
                    sortable: false
                }, {
                    label: "Start Date",
                    field: "start_date",
                    sortable: false
                }, {
                    label: "End Date",
                    field: "end_date",
                    sortable: false
                }],
            ownershipColumns: [{
                label: "Name",
                field: "name"
            }, {
                label: "Type",
                field: "type"
            },
            {
                label: "Ownership Type",
                field: "owner_type"
            },
            {
                label: "Status",
                field: "owner_status"
            },],
            previousNamesColumns: [{
                label: "Name",
                field: "company_name"
            }, {
                label: "Start Date",
                field: "start_date"
            }, {
                label: "End Date",
                field: "end_date"
            }],
            selectedData: {}
        }
    },
    props: {
        jurisdictionData: Array,
        selectedCompanyData: Object,
        officerRows: Array,
        ownershipRows: Array,
        previousNamesRows: Array,
        skipCheck:Boolean
    },
    components: {
        Button
    },
    computed: {
        searchedQuery() {
            return this.$route.query
        },
        currentPage() {
            return this.$route.params.type
        },
    },
    methods: {
        handleEntitiesSelection(type, data) {
            switch (type) {
                case "officer":
                    this.selectedData["officer"] = data.selectedRows
                    break;
                case "ownership":
                    this.selectedData["ownership"] = data.selectedRows
                    break;
                case "previousNames":
                    this.selectedData["previous_company"] = data.selectedRows
                    break;
            }
        },
        async saveSelectedEntities() {
            store.commit("SET_ENTITIES_DATA", this.selectedData);
            let payload = [];
            for (const property in this.selectedData) {
                this.selectedData[property].forEach(item => {
                    if (property == 'officer') {
                        payload.push({ name: `${item.name}`, type: "person", main_entity: true })
                    }
                    else if (property == 'ownership') {
                        payload.push({ name: `${item.name}`, type: "company", main_entity: true })
                    }
                    else if (property == 'previous_company') {
                        payload.push({ name: `${item.company_name}`, type: "company", main_entity: true })
                    }
                })
            }
            if (!payload.length) {
                this.$toast.error("Please select at least one Entity")
                return;
            }
            payload.unshift(
                { 
                    name: this.selectedCompanyData.company.name, 
                    type: "company", 
                    main_entity: true ,
                    root_entity: {
                        code: this.selectedCompanyData.company.jurisdiction_code,
                        doc_id: this.selectedCompanyData.doc_id,
                        company_number: this.selectedCompanyData.company.company_number,
                        query_id: this.selectedCompanyData.query_id
                    }
                }
            )
            let main_entity =  await updateEntity(payload, this.$route.query.neo_uuid);
            sessionStorage.setItem(`main_entity`, JSON.stringify(main_entity.data?.data?.entity_data));
            sessionStorage.setItem(`SelectedData`, JSON.stringify(this.selectedCompanyData.company));
            this.$router.push({
                name: "entity-details",
                params: { tool: "Entity-Details" },  
                query: {
                    case_id: this.$route.query.case_id,
                    code: this.selectedCompanyData.company.jurisdiction_code,
                    doc_id: this.selectedCompanyData.doc_id,
                    company: this.selectedCompanyData.company.company_number,
                    query_id: this.selectedCompanyData.query_id
                }
            }) 
        },
        async skipRecordsDetails(){
            let payload=[{  
                name: this.$route.query.name, 
                type: "company", 
                main_entity: true ,}]
            await updateEntity(payload, this.$route.query.neo_uuid);
            this.$router.push({
                name: "entity-details",
                params: { tool: "Entity-Details" },  
                query: {
                    case_id: this.$route.query.case_id,
                    name:this.$route.query.name,
                    skip:true
                }
            })

        }
    },
}