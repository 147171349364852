
import Button from "../../../components/button";
import searchedRecords from "../searched-records";
import companyDetails from "../../components/companyDetails";
import { getCompanySearchQueryId, getjurisdictionList, createCase, updateCaseName } from "./services"

export default {
    name: "lightray-searched-records",
    components: {
        "searched-records": searchedRecords,
        "company-details": companyDetails,
        Button,
    },
    data() {
        return {
            companyKeyword: null,
            companyNumberKeyword: null,
            companySearched: false,
            allCompanyData: [],
            rows: [],
            mode: "keyword",
            sources: ["opencorporates"],
            queryId: null,
            showEntities: false,
            loadingResult: false,
            loadingResultCompNumber: false,
            allJurisdictionData: null,
            jurisdictionSelection: null,
            showEntitiesSelection: false,
        }
    },
    computed: {
        currentPage() {
            return this.$route.params.type
        },
        searchQuery(){
            return this.$route.query
        }
    },
    async mounted() {
        console.log("test")
        await this.jurisdictionList()
      
    },
    methods: {
        async searchCaseCompany(type) {
            try {
                if (!this.companyKeyword && !this.companyNumberKeyword) {
                    this.$toast.error("Please enter company name or number")
                    return;
                }
                if (type == 'companyName') this.loadingResult = true;
                else this.loadingResultCompNumber = true;
                const searchedCompany = type == 'companyName' ? this.companyKeyword : this.companyNumberKeyword
                const casePayload = { package_id: this.$route.query.package_id }
                const { data } = await createCase(casePayload);

                const sourcePayload = { id: data.id, neo_case_id: data.neo_case_id }
                let queryIdResult = await getCompanySearchQueryId(sourcePayload)
                let companySearchPayload = {
                    name: searchedCompany,
                    request_id: queryIdResult.data.request_id,
                    "page": 1,
                    "per_page": 10,
                }
                let domainPayload = { query: searchedCompany }
                this.$store.dispatch('setSearchData', { companySearchPayload, domainPayload });
                let custmPayload = { case_name: searchedCompany, neo_case_id: data.id }
                await updateCaseName(custmPayload)
                this.loadingResult = false;
                this.loadingResultCompNumber = false;
                this.$router.push({
                    name: "search-company-records",
                    params: { type: "searched-records" },
                    query: {
                        queryId: queryIdResult.data.request_id,
                        name: searchedCompany,
                        jurisdiction: this.jurisdictionSelection,
                        case_id: data.neo_case_id,
                        package_id: this.$route.query.package_id,
                        client_id: this.$route.query.client_id,
                        neo_uuid: data.id,
                        selectEntity:false
                    }
                })
            }
            catch (err) {
                console.log('err', err)
                this.$toast.error("Something went wrong.",)
                this.loadingResultCompNumber = false;
                this.loadingResult = false;
            }

        },
        async jurisdictionList() {
            const result = await getjurisdictionList()
            this.allJurisdictionData = result.data.data
        }
    }
}